// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "                <th>Lookupd Conns.</th>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <tr "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.out_of_date : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                <td>"
    + alias4(((helper = (helper = helpers.hostname || (depth0 != null ? depth0.hostname : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hostname","hash":{},"data":data}) : helper)))
    + "</td>\n                <td><a class=\"link\" href=\"/nodes/"
    + alias4(((helper = (helper = helpers.broadcast_address || (depth0 != null ? depth0.broadcast_address : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"broadcast_address","hash":{},"data":data}) : helper)))
    + ":"
    + alias4(((helper = (helper = helpers.http_port || (depth0 != null ? depth0.http_port : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"http_port","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.broadcast_address || (depth0 != null ? depth0.broadcast_address : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"broadcast_address","hash":{},"data":data}) : helper)))
    + "</a></td>\n                <td>"
    + alias4(((helper = (helper = helpers.tcp_port || (depth0 != null ? depth0.tcp_port : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tcp_port","hash":{},"data":data}) : helper)))
    + "</td>\n                <td>"
    + alias4(((helper = (helper = helpers.http_port || (depth0 != null ? depth0.http_port : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"http_port","hash":{},"data":data}) : helper)))
    + "</td>\n                <td>"
    + alias4(((helper = (helper = helpers.version || (depth0 != null ? depth0.version : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"version","hash":{},"data":data}) : helper)))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depths[1] != null ? depths[1].nsqlookupd : depths[1])) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                <td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.topics : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </td>\n            </tr>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "class=\"warning\"";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <td>\n                    <a class=\"conn-count btn btn-default btn-xs "
    + ((stack1 = (helpers.unlesseq || (depth0 && depth0.unlesseq) || helpers.helperMissing).call(alias1,((stack1 = (depths[2] != null ? depths[2].nsqlookupd : depths[2])) != null ? stack1.length : stack1),((stack1 = (depth0 != null ? depth0.remote_addresses : depth0)) != null ? stack1.length : stack1),{"name":"unlesseq","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.remote_addresses : depth0)) != null ? stack1.length : stack1), depth0))
    + "</a>\n                    <div style=\"display: none;\">\n                        "
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.remote_addresses : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    </div>\n                </td>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "btn-warning";
},"9":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(depth0, depth0))
    + "<br/>";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <span class=\"badge\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.topics : depth0)) != null ? stack1.length : stack1), depth0))
    + "</span>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.topics : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <a href=\"/topics/"
    + alias4(((helper = (helper = helpers.topic || (depth0 != null ? depth0.topic : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic","hash":{},"data":data}) : helper)))
    + "\" class=\"link label "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.tombstoned : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.tombstoned : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = helpers.topic || (depth0 != null ? depth0.topic : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic","hash":{},"data":data}) : helper)))
    + "</a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "label-warning";
},"15":function(container,depth0,helpers,partials,data) {
    return "label-primary";
},"17":function(container,depth0,helpers,partials,data) {
    return "title=\"this topic is currently tombstoned on this node\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = container.invokePartial(partials.warning,depth0,{"name":"warning","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(partials.error,depth0,{"name":"error","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"row\">\n    <div class=\"col-md-12\">\n        <h2>NSQd Nodes ("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.collection : depth0)) != null ? stack1.length : stack1), depth0))
    + ")</h2>\n    </div>\n</div>\n\n<div class=\"row\">\n    <div class=\"col-md-12\">\n        <table class=\"table table-condensed table-bordered\">\n            <tr>\n                <th>Hostname</th>\n                <th>Broadcast Address</th>\n                <th>TCP Port</th>\n                <th>HTTP Port</th>\n                <th>Version</th>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.nsqlookupd : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                <th>Topics</th>\n            </tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.collection : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </table>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
