// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"row\">\n    <div class=\"col-md-6\">\n        <h4>Topic Message Queue</h4>\n        <div class=\"alert alert-warning\">\n            <h4>Notice</h4> No producers exist for this topic.\n            <p>See <a href=\"/lookup\">Lookup</a> for more information.\n        </div>\n    </div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isAdmin : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"row\">\n    <div class=\"col-md-12\">\n    <h4>Topic Message Queue</h4>\n    <table class=\"table table-bordered table-condensed\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <tr>\n            <th>NSQd Host</th>\n            <th>Depth</th>\n            <th>Memory + Disk</th>\n            <th>Messages</th>\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.graph_active : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            <th>Channels</th>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1),{"name":"each","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.nodes : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <tr class=\"info\">\n            <td>Total:</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.memory_depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + " + "
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.backend_depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.message_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.graph_active : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.channels : depth0)) != null ? stack1.length : stack1),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.graph_active : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </table>\n    </div>\n</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row topic-actions\">\n    <div class=\"col-md-2\">\n        <button class=\"btn btn-medium btn-warning\" data-action=\"empty\">Empty Queue</button>\n    </div>\n    <div class=\"col-md-2\">\n        <button class=\"btn btn-medium btn-danger\" data-action=\"delete\">Delete Topic</button>\n    </div>\n    <div class=\"col-md-2\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.paused : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-medium btn-success\" data-action=\"unpause\">UnPause Topic</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-medium btn-primary\" data-action=\"pause\">Pause Topic</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <tr>\n            <th colspan=\""
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.graph_active : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "\"></th>\n            <th colspan=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1), depth0))
    + "\">E2E Processing Latency</th>\n        </tr>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "6";
},"12":function(container,depth0,helpers,partials,data) {
    return "5";
},"14":function(container,depth0,helpers,partials,data) {
    return "<th>Rate</th>";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                <th>"
    + alias3((helpers.floatToPercent || (depth0 && depth0.floatToPercent) || alias2).call(alias1,(depth0 != null ? depth0.quantile : depth0),{"name":"floatToPercent","hash":{},"data":data}))
    + "<sup>"
    + alias3((helpers.percSuffix || (depth0 && depth0.percSuffix) || alias2).call(alias1,(depth0 != null ? depth0.quantile : depth0),{"name":"percSuffix","hash":{},"data":data}))
    + "</sup></th>\n";
},"18":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <tr>\n            <td>\n                <button class=\"btn-link red tombstone-link\" data-node=\""
    + alias3(((helper = (helper = helpers.node || (depth0 != null ? depth0.node : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"node","hash":{},"data":data}) : helper)))
    + "\" data-topic=\""
    + alias3(container.lambda((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "\" style=\"padding: 0 6px; border: 0;\">✘</button>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.show_broadcast_address : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.program(21, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.paused : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.memory_depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + " + "
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.backend_depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.message_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].graph_active : depths[1]),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.channels : depth0)) != null ? stack1.length : stack1),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].graph_active : depths[1]),{"name":"if","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                "
    + alias4(((helper = (helper = helpers.hostname_port || (depth0 != null ? depth0.hostname_port : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hostname_port","hash":{},"data":data}) : helper)))
    + " (<a class=\"link\" href=\"/nodes/"
    + alias4(((helper = (helper = helpers.node || (depth0 != null ? depth0.node : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"node","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.node || (depth0 != null ? depth0.node : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"node","hash":{},"data":data}) : helper)))
    + "</a>)\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <a class=\"link\" href=\"/nodes/"
    + alias4(((helper = (helper = helpers.node || (depth0 != null ? depth0.node : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"node","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.hostname_port || (depth0 != null ? depth0.hostname_port : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hostname_port","hash":{},"data":data}) : helper)))
    + "</a>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return " <span class=\"label label-primary\">paused</span>";
},"25":function(container,depth0,helpers,partials,data) {
    return "                <td class=\"bold rate\" target=\""
    + container.escapeExpression((helpers.rate || (depth0 && depth0.rate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"topic",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),"",{"name":"rate","hash":{},"data":data}))
    + "\"></td>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1),{"name":"each","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                <td>\n                    <span title=\""
    + alias3((helpers.floatToPercent || (depth0 && depth0.floatToPercent) || alias2).call(alias1,(depth0 != null ? depth0.quantile : depth0),{"name":"floatToPercent","hash":{},"data":data}))
    + ": min = "
    + alias3((helpers.nanotohuman || (depth0 && depth0.nanotohuman) || alias2).call(alias1,(depth0 != null ? depth0.min : depth0),{"name":"nanotohuman","hash":{},"data":data}))
    + ", max = "
    + alias3((helpers.nanotohuman || (depth0 && depth0.nanotohuman) || alias2).call(alias1,(depth0 != null ? depth0.max : depth0),{"name":"nanotohuman","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.nanotohuman || (depth0 && depth0.nanotohuman) || alias2).call(alias1,(depth0 != null ? depth0.average : depth0),{"name":"nanotohuman","hash":{},"data":data}))
    + "</span>\n                </td>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <tr class=\"graph-row\">\n                <td></td>\n                <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"topic",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),"","depth",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"topic",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),"","depth",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n                <td></td>\n                <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"topic",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),"","message_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"topic",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),"","message_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n                <td></td>\n                <td></td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </tr>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "                <td colspan=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1), depth0))
    + "\">\n                    <a href=\""
    + alias1((helpers.large_graph || (depth0 && depth0.large_graph) || alias3).call(alias2,"e2e",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.e2e_processing_latency : depth0),"","e2e_processing_latency",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias1((helpers.sparkline || (depth0 && depth0.sparkline) || alias3).call(alias2,"e2e",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.e2e_processing_latency : depth0),"","e2e_processing_latency",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a>\n                </td>\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "<td class=\"bold rate\" target=\""
    + container.escapeExpression((helpers.rate || (depth0 && depth0.rate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"topic","*",(depth0 != null ? depth0.topic_name : depth0),"",{"name":"rate","hash":{},"data":data}))
    + "\"></td>";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <tr class=\"graph-row\">\n                <td></td>\n                <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"topic","*",(depth0 != null ? depth0.topic_name : depth0),"","depth",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"topic",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),"","depth",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n                <td></td>\n                <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"topic","*",(depth0 != null ? depth0.topic_name : depth0),"","message_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"topic",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),"","message_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n                <td></td>\n                <td></td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </tr>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "                <td colspan=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1), depth0))
    + "\">\n                    <a href=\""
    + alias1((helpers.large_graph || (depth0 && depth0.large_graph) || alias3).call(alias2,"e2e","*",(depth0 != null ? depth0.e2e_processing_latency : depth0),"","e2e_processing_latency",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias1((helpers.sparkline || (depth0 && depth0.sparkline) || alias3).call(alias2,"e2e",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.e2e_processing_latency : depth0),"","e2e_processing_latency",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a>\n                </td>\n";
},"38":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"col-md-6\">\n        <h4>Channel Message Queues</h4>\n        <div class=\"alert alert-warning\">\n            <h4>Notice</h4> No channels exist for this topic.\n            <p>Messages will queue at the topic until a channel is created.\n        </div>\n";
},"40":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <div class=\"col-md-12\">\n        <h4>Channel Message Queues</h4>\n        <table class=\"table table-bordered table-condensed\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(41, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <tr>\n                <th>Channel</th>\n                <th>Depth</th>\n                <th>Memory + Disk</th>\n                <th>In-Flight</th>\n                <th>Deferred</th>\n                <th>Requeued</th>\n                <th>Timed Out</th>\n                <th>Messages</th>\n                <th>Connections</th>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1),{"name":"each","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </tr>\n\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.channels : depth0),{"name":"each","hash":{},"fn":container.program(46, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </table>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <tr>\n                <th colspan=\""
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.graph_active : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(44, data, 0),"data":data})) != null ? stack1 : "")
    + "\"></th>\n                <th colspan=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1), depth0))
    + "\">E2E Processing Latency</th>\n            </tr>\n";
},"42":function(container,depth0,helpers,partials,data) {
    return "10";
},"44":function(container,depth0,helpers,partials,data) {
    return "9";
},"46":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <tr>\n                <th>\n                    <a class=\"link\" href=\"/topics/"
    + alias3((helpers.urlencode || (depth0 && depth0.urlencode) || alias2).call(alias1,(depth0 != null ? depth0.topic_name : depth0),{"name":"urlencode","hash":{},"data":data}))
    + "/"
    + alias3((helpers.urlencode || (depth0 && depth0.urlencode) || alias2).call(alias1,(depth0 != null ? depth0.channel_name : depth0),{"name":"urlencode","hash":{},"data":data}))
    + "\">"
    + alias3(((helper = (helper = helpers.channel_name || (depth0 != null ? depth0.channel_name : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"channel_name","hash":{},"data":data}) : helper)))
    + "</a>\n                    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.paused : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                </th>\n                <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n                <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.memory_depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + " + "
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.backend_depth : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n                <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.in_flight_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n                <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.deferred_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n                <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.requeue_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n                <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.timeout_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n                <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,(depth0 != null ? depth0.message_count : depth0),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n                <td>"
    + alias3((helpers.commafy || (depth0 && depth0.commafy) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.clients : depth0)) != null ? stack1.length : stack1),{"name":"commafy","hash":{},"data":data}))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(49, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].graph_active : depths[1]),{"name":"if","hash":{},"fn":container.program(52, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"47":function(container,depth0,helpers,partials,data) {
    return "<span class=\"label label-primary\">paused</span>";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1),{"name":"each","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"50":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                    <td>\n                        <span title=\""
    + alias3((helpers.floatToPercent || (depth0 && depth0.floatToPercent) || alias2).call(alias1,(depth0 != null ? depth0.quantile : depth0),{"name":"floatToPercent","hash":{},"data":data}))
    + ": min = "
    + alias3((helpers.nanotohuman || (depth0 && depth0.nanotohuman) || alias2).call(alias1,(depth0 != null ? depth0.min : depth0),{"name":"nanotohuman","hash":{},"data":data}))
    + ", max = "
    + alias3((helpers.nanotohuman || (depth0 && depth0.nanotohuman) || alias2).call(alias1,(depth0 != null ? depth0.max : depth0),{"name":"nanotohuman","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.nanotohuman || (depth0 && depth0.nanotohuman) || alias2).call(alias1,(depth0 != null ? depth0.average : depth0),{"name":"nanotohuman","hash":{},"data":data}))
    + "</span>\n                    </td>\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <tr class=\"graph-row\">\n                <td></td>\n                <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"depth",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"depth",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n                <td></td>\n                <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"in_flight_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"in_flight_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n                <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"deferred_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"deferred_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n                <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"requeue_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"requeue_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n                <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"timeout_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"timeout_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n                <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"message_count",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"message_count",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n                <td><a href=\""
    + alias3((helpers.large_graph || (depth0 && depth0.large_graph) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"clients",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((helpers.sparkline || (depth0 && depth0.sparkline) || alias2).call(alias1,"channel",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.topic_name : depth0),(depth0 != null ? depth0.channel_name : depth0),"clients",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a></td>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </tr>\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "                    <td colspan=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.e2e_processing_latency : depth0)) != null ? stack1.percentiles : stack1)) != null ? stack1.length : stack1), depth0))
    + "\">\n                        <a href=\""
    + alias1((helpers.large_graph || (depth0 && depth0.large_graph) || alias3).call(alias2,"e2e",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.e2e_processing_latency : depth0),"","e2e_processing_latency",{"name":"large_graph","hash":{},"data":data}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias1((helpers.sparkline || (depth0 && depth0.sparkline) || alias3).call(alias2,"e2e",(depth0 != null ? depth0.node : depth0),(depth0 != null ? depth0.e2e_processing_latency : depth0),"","e2e_processing_latency",{"name":"sparkline","hash":{},"data":data}))
    + "\"></a>\n                    </td>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return ((stack1 = container.invokePartial(partials.warning,depth0,{"name":"warning","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(partials.error,depth0,{"name":"error","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<ol class=\"breadcrumb\">\n  <li><a class=\"link\" href=\"/\">Streams</a>\n  <li class=\"active\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</li>\n</ol>\n\n<div class=\"row\">\n    <div class=\"col-md-6\">\n        <blockquote>\n            <p>Topic: <strong>"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</strong>\n        </blockquote>\n    </div>\n</div>\n\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.nodes : depth0)) != null ? stack1.length : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\n\n<div class=\"row\">\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.channels : depth0)) != null ? stack1.length : stack1),{"name":"unless","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.program(40, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
